.ProjectsPage .CardBg .MuiCardHeader-root {
    padding-left: 0;
}

.ProjectsPage .CardBg .MuiCardHeader-root:not(.MuiCardHeader-title) .MuiTypography-root {
    padding-left: 8px;
}

.ProjectsPage .ProjectsItemContent .ProjectsItemSubtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.ProjectsPage .MuiCardHeader-content .MuiTypography-h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media (max-width: 600px) {
    .ProjectsPage .MuiCardHeader-content .MuiTypography-h5 {
        white-space: unset;
        -webkit-line-clamp: inherit;
    }
}