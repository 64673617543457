.BlogView .ArticleContent {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    line-height: 22px;
}

.BlogView .ArticleContent img {
    max-width: 100%;
}

.BlogView .ArticleContent p > img {
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 5%), 0 5px 8px 0 rgb(0 0 0 / 5%), 0 1px 14px 0 rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.BlogView .ArticleContent a {
    color: #2298db;
}

.BlogView .ArticleContent pre {
    display: grid;
}

.BlogView .ArticleContent pre,
.BlogView .ArticleContent .PrettyImage {
    max-width: 100%;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 15%), 0 5px 8px 0 rgb(0 0 0 / 5%), 0 1px 14px 0 rgb(0 0 0 / 5%);
    background: #161c1e;
    box-sizing: border-box;
    border-radius: 15px 15px 10px 10px;
    position: relative;
    border-top: 36px solid #E4E9EF;
    overflow: inherit;
    padding: 0;
}

.BlogView .ArticleContent .PrettyImage {
    padding: 4px 3px 0 3px;
    background-color: #7b8183;
}

.BlogView .ArticleContent pre:before,
.BlogView .ArticleContent .PrettyImage:before {
    content: "";
    position: absolute;
    right: 15px;
    top: -22px;
    width: 10px;
    height: 10px;
    background-color: #48CB6E;
    border-radius: 50%;
}

.BlogView .ArticleContent pre:after,
.BlogView .ArticleContent .PrettyImage:after {
    content: "";
    position: absolute;
    right: 38px;
    top: -22px;
    width: 10px;
    height: 10px;
    background-color: #FBBC5B;
    border-radius: 50%;
}

.BlogView .ArticleContent .PrettyImage img {
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    margin-bottom: -2px;
}

.BlogView .ArticleContent h1 > code,
.BlogView .ArticleContent h2 > code,
.BlogView .ArticleContent h3 > code,
.BlogView .ArticleContent h4 > code,
.BlogView .ArticleContent p > code {
    background: #646464;
    color: #fff;
    font-weight: bold;
    padding: 3px 6px 2px 7px;
    border-radius: 4px;
    white-space: nowrap;
    font-family: monospace;
    font-size: 12px;
    margin-right: 2px;
}

.BlogView .ArticleContent h1 > code,
.BlogView .ArticleContent h2 > code,
.BlogView .ArticleContent h3 > code,
.BlogView .ArticleContent h4 > code {
    background: #e3e3e3;
    color: black;
}

.BlogView .ArticleContent h1 > code {
    font-size: 23px;
}

.BlogView .ArticleContent h2 > code {
    font-size: 20px;
}

.BlogView .ArticleContent h3 > code {
    font-size: 17px;
}

.BlogView .ArticleContent h4 > code {
    font-size: 15px;
}

.BlogView .ArticleContent h1,
.BlogView .ArticleContent h2,
.BlogView .ArticleContent h3,
.BlogView .ArticleContent h4 {
    margin-top: 32px;
}

.BlogView .ArticleContent pre > code {
    overflow: auto;
    display: block;
    padding: 15px 15px 12px 15px;
    font-size: 14px;
}

.BlogView .ArticleContent pre.language-js > code:before,
    .BlogView .ArticleContent pre.language-javascript > code:before {
    content: "JavaScript";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-kotlin > code:before {
    content: "Kotlin";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-bash > code:before {
    content: "Bash";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-shell > code:before {
    content: "Shell";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-java > code:before {
    content: "Java";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-toml > code:before {
    content: "TOML";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-rust > code:before {
    content: "Rust";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-python > code:before {
    content: "Python";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-json > code:before {
    content: "Json";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-php > code:before {
    content: "PHP";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-c > code:before {
    content: "C";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-cmake > code:before {
    content: "CMake";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-yaml > code:before {
    content: "YAML";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent pre.language-dart > code:before {
    content: "Dart";
    position: absolute;
    top: -27px;
    left: 15px;
    z-index: 100;
    color: #727272;
    font-size: 12px;
    font-weight: bold;
}

.BlogView .ArticleContent blockquote {
    border-left: 3px solid #2298db;
    margin: 0;
    padding: 0 0 0 10px;
    position: relative;
    font-style: italic;
}
