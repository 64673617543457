.BlogsPage .BlogItemContent .BlogItemSubtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    -webkit-box-orient: vertical;
}

.BlogsPage .MuiCardHeader-content .MuiCardHeader-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media (max-width: 600px) {
    .BlogsPage .MuiCardHeader-content .MuiCardHeader-title {
        white-space: unset;
        -webkit-line-clamp: inherit;
    }
}


.BlogsPage .MuiCardHeader-root {
    padding-bottom: 0;
}